<template>
  <div>
    <SchoolEmployeeApplicationTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
    >
    </SchoolEmployeeApplicationTable>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        size="lg"
        @change="changePage"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SchoolEmployeeApplicationTable from "@/modules/school/components/management/leave/application/SchoolEmployeeApplicationTable";

export default {
  name: "SchoolStaffApproval",
  components: { SchoolEmployeeApplicationTable },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffLeaveApplicationApprovalList")
        .finally(() => (this.isBusy = false));
    },

    changePage(page) {
      this.$store.commit("setStaffLeaveApplicationApprovalListPage", page);
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffLeaveApplicationApprovalList",
      pagination: "getStaffLeaveApplicationApprovalPagination",
      isBusyStore: "getStaffLeaveApplicationApprovalBusy",
    }),
  },
};
</script>

<style scoped></style>
